import CONSTANTS from "@/shared/fields";
const {DELIVERY_EXTRA_FILTER, formOptionsPreset} = CONSTANTS;

export const formOptionsGen = () => {
  return [
    [
      {
        name: '등록상태', key: 'goods_status', options: [
          {text: '전체', value: 'ALL'},
          {text: '미등록', value: 'unregistered', variant: 'warning'},
          {text: 'Processing', value: 'processing', variant: 'info'},
          {text: 'Registered', value: 'registered', variant: 'success'},
          {text: 'Terminated', value: 'terminated', variant: 'danger'}
        ]
      },
      {
        name: '재고상태', key: 'stock_status', options: [
          {text: '전체', value: 'ALL'},
          {text: '재고있음', value: 'normal', variant: 'success'},
          {text: '품절', value: 'runout', variant: 'warning'}
        ]
      },
      {
        name: '노출상태', key: 'display_status', options: [
          {text: '전체', value: 'ALL'},
          {text: '노출', value: 'view', variant: 'success'},
          {text: '미노출', value: 'notview', variant: 'warning'}
        ]
      },
      {
        name: '연동형태', key: 'manual', options: [
          {text: '전체', value: 'ALL'},
          {text: '자동(FEED)', value: 'auto', variant: 'info'},
          {text: '파트너관리', value: 'manual', variant: 'success'}
        ]
      },
      {
        name: '배송유형', key: 'delivery_type', options: [
          {text: '전체', value: 'ALL'},
          {text: '해외', value: '해외', variant: 'success'},
          {text: '국내', value: '국내', variant: 'primary'},
        ]
      },
      {name: '오늘도착', key: 'oneday_delivery', options: formOptionsPreset.AVAIL_Y},
      {name: '오늘출발', key: 'today_pick', options: formOptionsPreset.AVAIL_Y},
      {name: '주문제작', key: 'orderMade', options: formOptionsPreset.YESNO_Y},
      {name: '배송방식', key: 'deliveryExtra', options: DELIVERY_EXTRA_FILTER.map(e => Object.assign(e, {variant: 'dark'}))},
      {name: '매장픽업', key: 'store_pickup', options: formOptionsPreset.AVAIL_Y},
      {name: '선물포장', key: 'gift_packing', options: formOptionsPreset.AVAIL_Y},
      {
        name: '검색엔진', key: 'hide_es', options: [
          {text: '전체', value: 'ALL'},
          {text: '포함', value: 'n', variant: 'success'},
          {text: '제외', value: 'y', variant: 'warning'},
        ]
      },

      // {key: 'divider'},
      {
        name: '정렬기준', key: 'sortKey', options: [
          {text: '생성시각', value: '_object_id', variant: 'primary'},
          {text: '등록시각', value: 'registered_dt', variant: 'info'},
          {text: '가격', value: 'price', variant: 'info'},
          {text: '소비자가', value: 'consumer', variant: 'info'},
          {text: '원가', value: 'supply', variant: 'info', role: 'DEV'},
          {text: '상품반품비', value: 'return_cost', variant: 'info'},
          {text: '할인율', value: 'discount_rate', variant: 'info'},
          {text: '재고', value: 'tot_stock', variant: 'info'},
          {text: '시즌', value: 'season', variant: 'info'},
          {text: '옵션수', value: 'optionCount', variant: 'info'},
          {text: '재고있는옵션수', value: 'optionHasStockCount', variant: 'info'},
          {text: '이미지 갯수', value: 'img_urls', variant: 'info'},
          {text: '오늘출발시각', value: 'today_pick_time', variant: 'info'},
        ]
      },
      {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR},
    ],
  ];
};

export const formFieldsGen = () => {
  return [
    {group: '상품속성'},
    {name: '발란코드', key: 'goods_no', type: 'number', enableExists: true},
    {name: 'Shop ID', key: 'shop_id', type: 'number', enableEnum: true, enableExists: true, enableStat: true, disableRange: true},
    {name: '상품명', key: 'name', type: 'string', op: 'like', disableRange: true, width: 180},
    {name: '원본상품명', key: 'name_raw', type: 'string', op: 'like', disableRange: true, width: 180},
    {name: '상품명 변경됨', key: 'nameReplaced', type: 'boolean', enableExists: true, width: 130},
    {name: '원본 브랜드명', key: 'brand_name', type: 'string', op: 'like', enableStat: true, disableRange: true, width: 150},
    {name: '원본 카테고리', key: 'origin_category', type: 'string', op: 'like', enableStat: true, disableRange: true, width: 150},
    {name: 'Sku ID', key: 'sku_id', type: 'string', enableExists: true, enableStat: true, width: 150},
    {name: '통합 Sku', key: 'matched_sku_id', type: 'string', enableExists: true, enableStat: true, width: 150},
    {name: '시즌', key: 'season', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 135},
    {name: '원산지', key: 'origin', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 135},
    {name: '매칭된 원산지', key: 'matched_origin', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 165},
    {name: '수입국', key: 'importCountry', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 135},
    {name: '컬러', key: 'color', type: 'string', enableEnum: true, enableExists: true, enableStat: true, disableRange: true, width: 135},
    {name: '매칭된 컬러', key: 'matched_color', type: 'string', enableExists: true, enableStat: true, disableRange: true, width: 130},
    {name: '상품관리코드', key: 'mng_code', type: 'string', enableExists: true, width: 150},
    {name: '상품타입', key: 'goodsType', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true, width: 145},
    {name: '중고등급', key: 'usedGrade', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true, width: 145},
    {name: '주문제작', key: 'orderMade', type: 'boolean', enableExists: true, enableStat: true, width: 145},
    {name: '배송방식', key: 'deliveryExtra', type: 'array', enableEnum: true, enableStat: true, width: 145},
    {group: '상품상태'},
    {
      name: '이미지처리', key: 'imageReady', type: 'boolean', boolPreset: [
        {text: '완료', value: true, variant: 'success'},
        {text: '처리중', value: false, variant: 'warning'},
      ], enableExists: true, width: 140
    },

    {group: '재고, 가격, 옵션'},
    {
      name: '판매가', key: 'price', type: 'number', enableExists: true, rangePreset: [
        {text: '~ 1만원', gte: '', lte: 10000},
        {text: '1 ~ 3만원', gte: 10000, lte: 30000},
        {text: '3 ~ 10만원', gte: 30000, lte: 100000},
        {text: '10 ~ 20만원', gte: 100000, lte: 200000},
        {text: '20 ~ 30만원', gte: 200000, lte: 300000},
        {text: '30 ~ 50만원', gte: 300000, lte: 500000},
        {text: '50 ~ 100만원', gte: 500000, lte: 1000000},
        {text: '100 ~ 300만원', gte: 1000000, lte: 3000000},
        {text: '300 ~ 500만원', gte: 3000000, lte: 5000000},
        {text: '500 ~ 1000만원', gte: 5000000, lte: 10000000},
        {text: '1000만원 ~', gte: 10000000, lte: ''},
      ]
    },
    {name: '소비자가', key: 'consumer', type: 'number'},
    {name: '원가', key: 'supply', type: 'number', role: 'DEV'},
    {name: '할인율', key: 'discount_rate', type: 'number', op: 'range', enableStat: true},
    {name: '상품반품비', key: 'return_cost', type: 'number', enableEnum: true, enableExists: true, enableStat: true, width: 145},
    {name: '총 재고', key: 'tot_stock', type: 'number', op: 'range', enableStat: true, width: 100},
    {name: '옵션 단위', key: 'sizeType', type: 'string', enableEnum: true, enableStat: true, width: 145},
    {name: '옵션명', key: 'options.optnm', type: 'string', op: 'like', enableEnum: true, enableStat: true, nested: true, width: 145},
    {name: '옵션 재고', key: 'options.stock', type: 'number', op: 'range', enableStat: true, nested: true},
    {name: '옵션 Size', key: 'options.Size', type: 'string', op: 'like', enableStat: true, nested: true, width: 160},
    {name: '옵션 Color', key: 'options.color', type: 'string', enableEnum: true, enableStat: true, nested: true, width: 145},
    {name: '옵션 판매가', key: 'options.goods_price', type: 'number', enableEnum: true, enableStat: true, nested: true, width: 145},
    {name: '옵션 추가금', key: 'options.option_price', type: 'number', enableEnum: true, enableStat: true, nested: true, width: 145},
    {name: '옵션수', key: 'optionCount', type: 'number', op: 'range', enableEnum: true, enableStat: true, width: 125},
    {name: '재고있는옵션수', key: 'optionHasStockCount', type: 'number', op: 'range', enableEnum: true, enableStat: true, width: 145},

    {group: '배송속성'},
    {name: '배송유형', key: 'delivery_type', type: 'string', enableEnum: true, enableExists: true, enableStat: true, disableRange: true, disableLike: true, width: 125},
    {name: '오늘출발시각', key: 'today_pick_time', type: 'string', placeholder: 'ex) 13:00', enableEnum: true, enableStat: true, disableLike: true, width: 125},

    {group: '등록정보'},
    {name: '생성위치', key: '_cat', type: 'string', enableExists: true, enableStat: true, width: 150},
    {name: '생성 APP', key: '_capp', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 150},
    {name: '생성시각', key: '_cdt', type: 'string', placeholder: 'ex) 2020-01-01 01:02:03', disableLike: true, width: 155},
    {name: '등록시각', key: 'registered_dt', type: 'string', placeholder: 'ex) 2020-01-01 01:02:03', enableExists: true, disableLike: true, width: 155},

    {group: '미노출처리'},
    {name: '미노출시각', key: '_notview_dt', type: 'string', placeholder: 'ex) 2020-01-01 01:02:03', enableExists: true, disableLike: true, width: 155},
    {name: '미노출처리자', key: '_notview_by', type: 'string', enableEnum: true, enableExists: true, enableStat: true, disableLike: true, width: 145},
    {name: '노출상태 변경이유', key: 'display_status_reason', type: 'string', enableEnum: true, enableExists: true, enableStat: true, disableRange: true, width: 170},

    {group: '가격통계'},
    {name: '옵션 최고-최저가 차이', key: 'priceStat.priceDiff', type: 'number', enableStat: true, width: 160},
    {name: '옵션 최고-최저가 차이(%)', key: 'priceStat.priceDiffPer', type: 'number', enableStat: true, enableEnum: true, width: 185},
    {name: '옵션 평균-최저가 차이', key: 'priceStat.priceAvgDiff', type: 'number', enableStat: true, width: 160},
    {name: '옵션 평균-최저가 차이(%)', key: 'priceStat.priceAvgDiffPer', type: 'number', enableStat: true, enableEnum: true, width: 185},
    {name: '옵션 재고가중평균-최저가 차이', key: 'priceStat.priceAvgStockDiff', type: 'number', enableStat: true, width: 210},
    {name: '옵션 재고가중평균-최저가 차이(%)', key: 'priceStat.priceAvgStockDiffPer', type: 'number', enableStat: true, enableEnum: true, width: 230},
    {name: '옵션 가격 표준편차', key: 'priceStat.priceStd', type: 'number', enableStat: true, width: 140},
    {name: '옵션 가격 표준편차/옵션 최저가', key: 'priceStat.priceStdDiv', type: 'number', enableStat: true, width: 210},
  ];
};

export const formIncExcGen = () => {
  return [
    {name: '발란코드', key: 'goods_no', type: 'number'},
    {name: '파트너상품코드', key: 'goods_id', type: 'string'},
    {name: 'gid', key: 'gid', type: 'string'},
    {name: 'Shop ID', key: 'shop_id', type: 'number'},
    {name: 'Sku ID', key: 'sku_id', type: 'string'},
    {name: '통합 Sku', key: 'matched_sku_id', type: 'string'},
    {name: '브랜드 코드', key: 'brand_no', type: 'number'},
    {name: '최종카테고리 코드', key: 'matched_category', type: 'string'},
    {name: '시즌', key: 'season', type: 'string'},
    {name: '원산지', key: 'origin', type: 'string'},
    {name: '매칭된 원산지', key: 'matched_origin', type: 'string'},
    {name: '컬러', key: 'color', type: 'string'},
    {name: '매칭된 컬러', key: 'matched_color', type: 'string'},
    {name: '옵션 단위', key: 'sizeType', type: 'string'},
    {name: '상품관리코드', key: 'mng_code', type: 'string'},
    {name: '오늘출발시각', key: 'today_pick_time', type: 'string'},
    {name: '미노출처리자', key: '_notview_by', type: 'string'},
    {name: '생성 APP', key: '_capp', type: 'string'},
  ];
}

export const groupFieldsGen = () => {
  return [
    {text: 'Shop ID', value: 'shop_id', type: 'number'},
    {text: '브랜드 코드', value: 'brand_no', type: 'number'},
    {text: '최종카테고리 코드', value: 'matched_category', type: 'string'},
    {text: '원본 브랜드명', value: 'brand_name', type: 'string'},
    {text: '원본 카테고리', value: 'origin_category', type: 'string'},
    {text: '시즌', value: 'season', type: 'string'},
    {text: '컬러', value: 'color', type: 'string'},
    {text: '매칭된 컬러', value: 'matched_color', type: 'string'},
    {text: '원산지', value: 'origin', type: 'string'},
    {text: '수입국', value: 'importCountry', type: 'string'},
    {text: '매칭된 원산지', value: 'matched_origin', type: 'string'},
    {text: '배송유형', value: 'delivery_type', type: 'string'},
    {text: '상품타입', value: 'goodsType', type: 'string'},
    {text: '중고등급', value: 'usedGrade', type: 'string'},
    {text: '주문제작', value: 'orderMade', type: 'boolean'},
    {text: '상품명 변경됨', value: 'nameReplaced', type: 'boolean'},

    {text: '이미지 수', value: 'img_urls', type: 'number'},
    {text: '옵션수', value: 'optionCount', type: 'number'},
    {text: '재고있는옵션수', value: 'optionHasStockCount', type: 'number'},
    {text: '옵션 단위', value: 'sizeType', type: 'string'},

    {text: '옵션 Color', value: 'options.color', type: 'string', nested: true},
    {text: '옵션 판매가', value: 'options.goods_price', type: 'number', nested: true},
    {text: '옵션 추가금', value: 'options.option_price', type: 'number', nested: true},
    {text: '옵션명', value: 'options.optnm', type: 'string', nested: true},
    {text: '옵션 Size', value: 'options.Size', type: 'string', nested: true},
    {text: '옵션 재고', value: 'options.stock', type: 'number', nested: true},
    {text: '옵션 선택값', value: 'options.opt', type: 'string', nested: true},
    {text: '옵션 직접입력', value: 'options.opt_direct', nested: true},

    {text: '특징', value: 'feature', type: 'string'},
    {text: '특징(직접입력)', value: 'featureEtc', type: 'string'},
    {text: 'FTA', value: 'fta', type: 'string'},
    {text: 'Sku ID', value: 'sku_id', type: 'string'},
    {text: '통합 Sku', value: 'matched_sku_id', type: 'string'},

    {text: '총 재고', value: 'tot_stock', type: 'number'},
    {text: '할인율', value: 'discount_rate', type: 'number'},

    {text: '연동형태', value: 'manual', type: 'boolean'},
    {text: '검색엔진제외', value: 'hide_es', type: 'boolean'},
    {text: '매장픽업가능', value: 'store_pickup', type: 'boolean'},
    {text: '선물포장가능', value: 'gift_packing', type: 'boolean'},
    {text: '오늘도착가능', value: 'oneday_delivery', type: 'string'},
    {text: '오늘출발가능', value: 'today_pick', type: 'boolean'},
    {text: '오늘출발시각', value: 'today_pick_time', type: 'string'},
    {text: '상품반품비', value: 'return_cost', type: 'number'},
    {text: '상품교환비', value: 'exchange_cost', type: 'number'},

    {text: '등록상태', value: 'goods_status', type: 'string'},
    {text: '노출상태', value: 'display_status', type: 'string'},
    {text: '노출상태 변경이유', value: 'display_status_reason', type: 'string'},
    {text: '미노출처리자', value: '_notview_by', type: 'string'},

    {text: '생성위치', value: '_cat', type: 'string'},
    {text: '생성 APP', value: '_capp', type: 'string'},
    {text: '생성 User ID', value: '_cuid', type: 'string'},

    {text: "옵션 최고-최저가 차이", value: "priceStat.priceDiff", type: "number"},
    {text: "옵션 최고-최저가 차이(%)", value: "priceStat.priceDiffPer", type: "number"},
    {text: "옵션 평균-최저가 차이", value: "priceStat.priceAvgDiff", type: "number"},
    {text: "옵션 평균-최저가 차이(%)", value: "priceStat.priceAvgDiffPer", type: "number"},
    {text: "옵션 재고가중평균-최저가 차이", value: "priceStat.priceAvgStockDiff", type: "number"},
    {text: "옵션 재고가중평균-최저가 차이(%)", value: "priceStat.priceAvgStockDiffPer", type: "number"},
    {text: "옵션 가격 표준편차", value: "priceStat.priceStd", type: "number"},
    {text: "옵션 가격 표준편차/옵션 최저가", value: "priceStat.priceStdDiv", type: "number"}
  ];
};
