<template>
  <div>
    <b-tabs class="mb-2" v-model="tabIndex">
      <b-tab title="DB 검색">
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-button variant="primary" @click="list()" :disabled="busy.list">
              <i class="fa fa-search"></i> 검색
              <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input id="search" type="text" placeholder="상품명, goods_id, src_id, sku_id를 넣어주세요" v-model="form.list.search" @keypress.enter="list()"
                        v-focus></b-form-input>
          <!--<b-input-group-append>
            <b-button variant="light" v-b-toggle.collapse1>검색조건 <i class="fa fa-chevron-down"></i></b-button>
          </b-input-group-append>-->
        </b-input-group>
        <shop-preset :shop_type="true" v-if="$R('GOODS_X')" class="mb-2" v-model="form.list.shop"></shop-preset>

        <b-collapse id="collapse1" v-model="collapse.detail">
          <b-row class="mb-2">
            <b-col lg="6">
              <brand-preset v-model="form.list.brand" :hideDisabled="true"></brand-preset>
            </b-col>
            <b-col lg="6">
              <category-preset v-model="form.list.category"></category-preset>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col cols="12" lg="3">
              <div><small class="mb-n2">연동형태</small></div>
              <b-form-radio-group class="col-form-label" v-model="form.list.manual" :options="[
              {text: '전체', value: 'ALL'},
              {text: '자동(FEED)', value: 'auto'},
              {text: '파트너관리', value: 'manual'}
            ]"></b-form-radio-group>
            </b-col>
            <b-col cols="12" lg="6">
              <div><small class="mb-n2">상품유형</small></div>
              <b-form inline>
                <b-form-radio-group class="col-form-label" v-model="form.list.goodsType" :options="[
                {text: '전체', value: 'ALL'},
                {text: '새상품만', value: 'new'},
                {text: '빈티지만', value: 'used'}
              ]"></b-form-radio-group>
                <template v-if="form.list.goodsType === 'used'">
                  <b-button class="mr-1" size="sm" variant="light" @click="toggleUsedGrade()">전체</b-button>
                  <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
                  <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
                  <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
                  <b-form-checkbox-group v-model="form.list.usedGrade">
                    <b-form-checkbox v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{ s.text }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
              </b-form>
            </b-col>
            <b-col cols="12" lg="3">
              <div><small class="mb-n2">이미지 처리 상태</small></div>
              <b-form-radio-group class="col-form-label" v-model="form.list.imageReady" :options="[
              {text: '전체', value: 'ALL'},
              {text: '준비됨', value: 'ready'},
              {text: '미처리', value: 'notReady'}
            ]"></b-form-radio-group>
            </b-col>
            <b-col cols="12" lg="3">
              <div><small class="mb-n2">주문제작</small></div>
              <b-form-radio-group class="col-form-label" v-model="form.list.orderMade" :options="[
              {text: '전체', value: 'ALL'},
              {text: 'Y', value: true},
              {text: 'N', value: false}
            ]"></b-form-radio-group>
            </b-col>
            <b-col cols="12" lg="3">
              <small>배송방식</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.list.deliveryExtra" :options="$F.DELIVERY_EXTRA_FILTER"></b-form-radio-group>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col md="3">
              <div><small class="mb-n2">시즌값</small></div>
              <b-form-input type="text" placeholder="22SS 등의 시즌값" v-model="form.list.launch_date"></b-form-input>
            </b-col>
            <b-col md="3">
              <div><small class="mb-n2">할인율 범위</small></div>
              <b-form inline>
                <b-form-input class="text-center w-65px" placeholder="ex) 0" v-model.number="form.list.discountFrom"></b-form-input>
                ~
                <b-form-input class="text-center w-65px" placeholder="ex) 99" v-model.number="form.list.discountTo"></b-form-input>
              </b-form>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col md="3">
              <small>한 번에 가져올 상품 수</small>
              <b-form-input type="text" placeholder="한 번에 가져올 상품 수" v-model.number="form.list.limit"></b-form-input>
            </b-col>
            <b-col md="3">
              <small>총 재고합이 n 개 이상</small>
              <b-form-input type="text" placeholder="options 의 재고의 합이 이 숫자 이상입니다" v-model.number="form.list.tot_stock"></b-form-input>
            </b-col>
            <b-col md="3">
              <small>재고가 n 개 이상인 옵션이 존재</small>
              <b-form-input type="text" placeholder="options 의 재고가 n개 이상인 옵션이 존재합니다" v-model.number="form.list.opt_stock"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <small>goods_id</small>
              <b-form-textarea :rows="4" v-model="form.list.goods_id" placeholder="goods_id를 입력해주세요"></b-form-textarea>
              <small class="text-muted">엔터로 구분된 goods_id를 입력해주세요</small>
            </b-col>
            <b-col>
              <small>gid</small>
              <b-form-textarea :rows="4" v-model="form.list.gid" placeholder="gid(shop_id|goods_id)를 입력해주세요"></b-form-textarea>
              <small class="text-muted">엔터로 구분된 gid를 입력해주세요</small>
            </b-col>
          </b-row>
          <!--<b-form-checkbox class="mb-2" v-model="form.list.new_goods_id">등록되지 않은 상품(Processing 포함)만 가져옵니다</b-form-checkbox>-->
          <b-form-checkbox class="mb-2" v-model="form.list.include_not_found">원본이 사라진 상품도 가져옵니다(일시적인 오류로 사라질 수 있음)</b-form-checkbox>
          <b-form-checkbox v-if="$R('GOODS_X')" class="mb-2" v-model="form.list.minimal">필수정보만 가져옵니다(Update 등을 빠르게 하기 위해)</b-form-checkbox>
        </b-collapse>
        발란코드가 있는 상품(processing 포함)만 가져옵니다
        <b-form-radio-group class="col-form-label" v-model="form.list.hasGoodsNo" :options="$C.OPTIONS.YESNO"></b-form-radio-group>

        <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
        <b-button class="mr-1" variant="warning" @click="resetForm()">초기화</b-button>
        <b-button class="mr-1" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          검색엔진
          <b-badge class="ml-1" variant="success">BETA</b-badge>
        </template>

        <b-input-group class="mb-1">
          <b-input-group-prepend>
            <b-button variant="primary" @click="list()" :disabled="busy.list">
              <i class="fa fa-search"></i> 검색
              <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input type="text" placeholder="발란코드, 파트너상품코드, 카테고리, 상품명, SKU, 통합 SKU, 시즌" v-model="form.list.search" @keypress.enter="list()" v-focus></b-form-input>
          <b-input-group-append>
            <b-button variant="light" @click="getEsQuery()">쿼리확인</b-button>
          </b-input-group-append>
        </b-input-group>

        <form-options v-model="form.list" v-bind="{formOptions}">
          <template v-slot:slotEnd>
            <div class="flex-grow-0 mb-1 mr-2">
              <small>Color 선택</small><br/>
              <color-checkbox v-model="form.list.matched_color"></color-checkbox>
            </div>
            <div class="flex-grow-0 mb-1 mr-2">
              <small>기본 상품수</small><br/>
              <b-form-input class="text-center w-70px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.list.limit" @keypress.enter="list()"></b-form-input>
            </div>
          </template>
        </form-options>

        <b-collapse id="collapseES" v-model="collapse.esDetail">
          <hr/>
          <b-row>
            <b-col v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')" class="mb-1" cols="12" md="12" xl="4">
              <shop-preset v-model="form.list.shop"></shop-preset>
            </b-col>
            <b-col class="mb-1" cols="12" md="6" xl="4">
              <brand-preset class="" v-model="form.list.brand" :hideDisabled="true"></brand-preset>
            </b-col>
            <b-col class="mb-1" cols="12" md="6" xl="4">
              <category-preset class="" v-model="form.list.category"></category-preset>
            </b-col>
          </b-row>
          <div class="mt-n2">
            <small class="pointer text-warning" v-b-toggle.collapseExc>제외할 Shop, Brand, Category 설정</small>
            <div class="py-1">
              <b-collapse id="collapseExc" class="b-a-1 m-n1 p-2 border-warning">
                <b-row>
                  <b-col v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')" class="mb-1" cols="12" md="12" xl="4">
                    <shop-preset v-model="form.list.shop_exclude"></shop-preset>
                  </b-col>
                  <b-col class="mb-1" cols="12" md="6" xl="4">
                    <brand-preset class="" v-model="form.list.brand_exclude" :hideDisabled="true"></brand-preset>
                  </b-col>
                  <b-col class="mb-1" cols="12" md="6" xl="4">
                    <category-preset class="" v-model="form.list.category_exclude"></category-preset>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>
          </div>

          <div class="fs-12 bold">
            검색 필드
          </div>
          <form-fields ref="fields" v-model="form.list.fields" :name="$options.name" :customFormFields.sync="customFormFields"
                       v-bind="{formFields, defaultFields, statUri: '/goods/mapped/fieldStat'}" @enter="list()"></form-fields>
          <form-inc-exc ref="incExc" v-model="form.list.incExc" :name="$options.name" :customFormIncExc.sync="customFormIncExc"
                        v-bind="{formIncExc, defaultIncExc}"></form-inc-exc>

          <div class="mb-2">
            <b-form-checkbox v-if="$R('BALAANEER')" v-model="form.list.minimal" inline>필수정보만 가져옵니다(Update 등을 빠르게 하기 위해)</b-form-checkbox>
          </div>
        </b-collapse>

        <div class="mt-2 clearfix">
          <div class="pull-left">
            <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
            <b-button class="mr-1" variant="warning" @click="resetForm()">초기화</b-button>
            <b-button class="mr-1" variant="outline-primary" @click="modal.downIds = true">검색결과 ID Down</b-button>
            <b-button class="mr-1" variant="outline-success" v-b-toggle.collapseES>상세검색조건</b-button>
          </div>
          <div class="pull-right">
            <form-preset v-model="form.list" v-bind="{page: $options.name, defaultForm: defaultForm.list}" @resetForm="resetForm()"></form-preset>
          </div>
        </div>
      </b-tab>


      <b-tab>
        <template v-slot:title>
          통계
          <b-badge class="ml-1" variant="success">BETA</b-badge>
        </template>

        <b-row>
          <b-col xl="9">
            <b-input-group class="mb-1">
              <b-input-group-prepend>
                <b-button variant="success" @click="stat()" :disabled="busy.stat">
                  <i class="fa fa-pie-chart"></i> 통계
                  <b-spinner class="ml-1" small v-if="busy.stat"></b-spinner>
                </b-button>
              </b-input-group-prepend>
              <b-form-input type="text" placeholder="발란코드, 파트너상품코드, 카테고리, 상품명, SKU, 통합 SKU, 시즌" v-model="form.list.search" @keypress.enter="stat()" v-focus></b-form-input>
              <b-input-group-append>
                <b-button variant="light" @click="getEsQuery()">쿼리확인</b-button>
              </b-input-group-append>
            </b-input-group>

            <form-options v-model="form.list" v-bind="{formOptions, excludeKeys: ['sortKey', 'sortDir']}">
              <template v-slot:slotEnd>
                <div class="flex-grow-0 mb-1 mr-2">
                  <small>Color 선택</small><br/>
                  <color-checkbox v-model="form.list.matched_color"></color-checkbox>
                </div>
              </template>
            </form-options>

            <b-collapse id="collapseStat" v-model="collapse.statDetail">
              <hr/>
              <b-row>
                <b-col v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')" class="mb-1" cols="12" md="12" xl="4">
                  <shop-preset v-model="form.list.shop"></shop-preset>
                </b-col>
                <b-col class="mb-1" cols="12" md="6" xl="4">
                  <brand-preset class="" v-model="form.list.brand" :hideDisabled="true"></brand-preset>
                </b-col>
                <b-col class="mb-1" cols="12" md="6" xl="4">
                  <category-preset class="" v-model="form.list.category"></category-preset>
                </b-col>
              </b-row>
              <div class="mt-n2">
                <small class="pointer text-warning" v-b-toggle.collapseExc>제외할 Shop, Brand, Category 설정</small>
                <div class="py-1">
                  <b-collapse id="collapseExc" class="b-a-1 m-n1 p-2 border-warning">
                    <b-row>
                      <b-col v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')" class="mb-1" cols="12" md="12" xl="4">
                        <shop-preset v-model="form.list.shop_exclude"></shop-preset>
                      </b-col>
                      <b-col class="mb-1" cols="12" md="6" xl="4">
                        <brand-preset class="" v-model="form.list.brand_exclude" :hideDisabled="true"></brand-preset>
                      </b-col>
                      <b-col class="mb-1" cols="12" md="6" xl="4">
                        <category-preset class="" v-model="form.list.category_exclude"></category-preset>
                      </b-col>
                    </b-row>
                  </b-collapse>
                </div>
              </div>

              <div class="fs-12 bold">
                검색 필드
              </div>
              <form-fields ref="fields" v-model="form.list.fields" :name="$options.name" :customFormFields.sync="customFormFields"
                           v-bind="{formFields, defaultFields, statUri: '/goods/mapped/fieldStat'}" @enter="stat()"></form-fields>
              <form-inc-exc ref="incExc" v-model="form.list.incExc" :name="$options.name" :customFormIncExc.sync="customFormIncExc"
                            v-bind="{formIncExc, defaultIncExc}"></form-inc-exc>

              <div class="mb-2">
                <b-form-checkbox v-if="$R('BALAANEER')" v-model="form.list.minimal" inline>필수정보만 가져옵니다(Update 등을 빠르게 하기 위해)</b-form-checkbox>
              </div>
            </b-collapse>
          </b-col>
          <b-col class="border-left">
            <div class="fs-12 bold">
              Group By
            </div>
            <v-select v-model="form.aggs.groupFields" :options="groupFields" index="value" multiple push-tags placeholder="컬럼 선택"
                      @input="groupFieldsChange()"></v-select>
            <template v-for="(f, idx) in form.aggs.groupFields">
              <div class="mt-2" v-if="form.aggs.groupFieldSetting[f]" :key="idx">
                <div class="fs-12 bold">
                  {{groupFields.find(e => e.value === f).label}} Group Size
                  <i class="fa fa-question-circle ml-1" v-b-tooltip="'갯수가 많은 순으로 Group Size 만큼 가져오고 나머지는 (OTHER_COUNT) 에 포함됩니다.'"></i>
                </div>
                <b-form-input type="number" class="text-center w-100px" v-model.number="form.aggs.groupFieldSetting[f].size" placeholder="group size"></b-form-input>
              </div>
            </template>
          </b-col>
        </b-row>

        <div class="mt-2 clearfix">
          <div class="pull-left">
            <b-button class="mr-1" variant="success" @click="stat()" :disabled="busy.stat">통계<b-spinner class="ml-1" small v-if="busy.stat"></b-spinner></b-button>
            <b-button class="mr-1" variant="warning" @click="resetStatForm()">초기화</b-button>
            <b-button class="mr-1" variant="outline-success" v-b-toggle.collapseStat>상세검색조건</b-button>
          </div>
          <div class="pull-right">
            <form-preset v-model="form.list" v-bind="{page: $options.name, defaultForm: defaultForm.list}" @resetForm="resetForm()"></form-preset>
          </div>
        </div>
      </b-tab>
    </b-tabs>


    <b-modal title="Mapped" size="xl" v-if="modal.detail" v-model="modal.detail">
      <template v-slot:modal-title>
        <b-badge variant="success">{{ item.shop_id }}. {{ item.shop }}</b-badge>
        <b-badge class="ml-1" variant="warning">{{ item.brand_name }}</b-badge>
        <b-badge class="ml-1" variant="light">{{ item.origin_category }}</b-badge>
        <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.goods_id)">{{ item.goods_id }} <i class="fa fa-copy ml-1"></i></b-badge>
        <template v-if="item.goods_no">
          <b-badge class="ml-1 pointer" variant="info" @click="copyText(item.goods_no)">{{ item.goods_no }} <i class="fa fa-copy ml-1"></i></b-badge>
          <a :href="`${MALL_URL}/shop/goods/goods_view.php?goodsno=${item.goods_no}`" target="_blank"
             class="ml-1 badge alert-primary">발란몰 <i class="fa fa-external-link"></i></a>
        </template>
        <br/>
        <span v-if="item.goodsType === 'used'" class="text-danger">[빈티지{{ item.usedGrade || '등급없음' }}]</span> {{ item.name }}
        <div v-if="item.name_raw && item.name_raw !== item.name" class="fs-14" style="color: #aaa">
          {{ item.name_raw }}
          <b-badge variant="light" v-b-tooltip="'금칙어 규칙 등에 의해 변경되기 전의 원본 상품명입니다.'">원본상품명<i class="ml-1 fa fa-question-circle"></i></b-badge>
        </div>
      </template>

      <div class="clearfix">
        <h5 v-if="item.src_not_found">
          <small class="text-muted"><i class="fa fa-exclamation-circle"></i> {{ item.not_found._dt }} 이후로 원본정보에서 찾을 수 없습니다</small>
        </h5>
      </div>

      <b-row class="mb-2">
        <b-col cols="6" lg="3">
          <small>가격</small><br/>
          <div class="text-right pr-3">
            <div v-if="item.discount_rate > 0">
              <del>{{ $utils.rnc(item.price / (100 - item.discount_rate) * 100, 2) }}</del>
              {{ item.price_unit }}
            </div>
            <div>{{ $utils.rnc(item.price, 2) }} {{ item.price_unit }}</div>
            <div v-if="item.discount_rate > 0">({{ item.discount_rate }} %)</div>
          </div>
        </b-col>
        <b-col cols="6" lg="4">
          <div v-if="item.options && item.options.length">
            <small>{{ item.options[0].optnm }}</small><br/>
            <template v-for="(e, idx) in item.options">
              <div :key="idx">
                <b-badge variant="light">{{ e.Size }}</b-badge>
                <b-badge variant="success">{{ e.stock }}</b-badge>
                &nbsp;
                <span
                  v-if="e.goods_price !== item.price"><small>{{ $utils.rnc(e.goods_price, 2) }} {{ item.price_unit }} ({{ $utils.delta(e.goods_price - item.price, 2) }} {{ item.price_unit }})</small></span>
                <span v-else class="text-muted"><small>(가격동일)</small></span>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>

      <div class="mb-4 mp_img">
        <template v-if="item.imageReady && item.images">
          <small>일반 이미지 ({{ item.images.length }} 개)</small><br/>
          <div class="mt-2">
            <span v-for="(i, idx) in item.images">
              <img :id="`mapped_images-${idx}`" :src="`https://i.balaan.io/${item.image_path}${i.thumb_200}`" style="height:80px"
                   @click="$utils.open(`https://i.balaan.io/${item.image_path}${i.org_webp || i.org}`)"/>
              <b-popover :target="`mapped_images-${idx}`" placement="bottom" triggers="hover focus">
                <img :src="`https://i.balaan.io/${item.image_path}${i.org_webp || i.org}`" style="max-width:250px"/>
              </b-popover>
            </span>
          </div>
          <div class="mt-3" v-if="item.longImages">
            <small>긴 이미지로 분류된 이미지 ({{ item.longImages.length }} 개)</small><br/>
            <div class="mt-2">
              <span v-for="(i, idx) in item.longImages">
                <div style="width: 100px; max-height: 80px; overflow: hidden">
                  <img :id="`mapped_longImages-${idx}`" :src="`https://i.balaan.io/${item.image_path}${i.org_webp || i.org}`" style="width: 100%"
                       @click="$utils.open(`https://i.balaan.io/${item.image_path}${i.org_webp || i.org}`)"/>
                </div>
                <b-popover :target="`mapped_longImages-${idx}`" placement="bottom" triggers="hover focus">
                  <img :src="`https://i.balaan.io/${item.image_path}${i.org_webp || i.org}`" style="max-width:250px"/>
                </b-popover>
              </span>
            </div>
          </div>
          <a :href="`/#/pages/image/mapped/${encodeURIComponent(item.gid)}`" class="btn btn-primary btn-sm mt-1" target="_blank" v-if="item.image_path">이미지
            보기</a>
        </template>
        <template v-else>
          <small>이미지</small><br/>
          <div class="mt-2">
            <span v-for="(i, idx) in item.img_urls">
              <img :id="`mapped_imgs-${idx}`" :src="i" style="height:80px" @click="$utils.open(`${i}`)"/>
              <b-popover :target="`mapped_imgs-${idx}`" placement="bottom" triggers="hover focus">
                <img :src="i" style="max-width:250px"/>
              </b-popover>
            </span>
          </div>
        </template>
      </div>

      <b-row class="mb-2">
        <b-col cols="4">
          <div class="title-sm">상품코드</div>
          <template v-if="item.goods_no">
            <small>발란코드 : </small>
            <a :href="`/#/goods/${item.goods_no}`" target="_blank">
              <b-badge variant="primary">{{ item.goods_no }} <i class="fa fa-external-link"></i></b-badge>
            </a>
            <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.goods_no)"><i class="fa fa-copy"></i></b-badge>
            <a :href="`${MALL_URL}/shop/goods/goods_view.php?goodsno=${item.goods_no}`" target="_blank"
               class="ml-1 badge alert-primary">발란몰 <i class="fa fa-external-link"></i></a>
            <br/>
          </template>

          <small>SKU : </small>
          <template v-if="item.sku_id">
            <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.sku_id||'').replace(' ', '+')}`" target="_blank">
              <b-badge variant="success">{{ item.sku_id }} <i class="fa fa-external-link"></i></b-badge>
            </a>
            <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.sku_id)"><i class="fa fa-copy"></i></b-badge>
          </template>
          <br/>

          <small>통합 SKU : </small>
          <template v-if="item.matched_sku_id">
            <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.matched_sku_id||'').replace(' ', '+')}`" target="_blank">
              <b-badge variant="success">{{item.matched_sku_id}} <i class="fa fa-external-link"></i></b-badge>
            </a>
            <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.matched_sku_id)"><i class="fa fa-copy"></i></b-badge>
          </template>
          <br/>

          <small>파트너 상품코드 : </small>
          <a v-if="item.crawl_url && item.crawl_url.startsWith('http')" :href="`/r?u=${encodeURIComponent(item.crawl_url)}`"
             target="_blank">
            <b-badge variant="info">{{ item.goods_id || 'link' }} <i class="fa fa-external-link"></i></b-badge>
          </a>
          <b-badge v-else variant="light">{{ item.goods_id }}</b-badge>
          <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.goods_id)"><i class="fa fa-copy"></i></b-badge>
          <br/>

          <small>상품관리코드 : </small><b-badge variant="light">{{item.mng_code}}</b-badge>
          <b-badge v-if="item.mng_code" class="ml-1 pointer" variant="light" @click="copyText(item.mng_code)"><i class="fa fa-copy"></i></b-badge>
          <br/>
        </b-col>
        <b-col cols="4">
          <div class="title-sm">상품속성</div>
          <template v-for="(e, idx) in [item.goods_status, item.stock_status, item.display_status].map(e => $utils.badge(e))">
            <span v-html="e" :key="idx"></span>
          </template>
          <br/>
          <span :class="`badge alert-${item.delivery_type === '해외' ? 'success' : 'info'}`">
                {{ item.delivery_str }}
              </span>
          <span v-if="!item.orderMade && (!item.deliveryExtra || !item.deliveryExtra.includes('long'))" class="badge badge-light">{{ item.delivery_day }}일</span><br/>
          <template v-if="item.manual">
            <span class="badge alert-danger">파트너관리</span><br/>
          </template>
          <template v-if="item.oneday_delivery === 'Y'">
            <span class="badge alert-danger">오늘도착 가능</span><br/>
          </template>
          <template v-if="item.orderMade">
            <span class="badge alert-warning">주문제작</span><br/>
          </template>
          <template v-if="item.deliveryExtra && item.deliveryExtra.length">
            <span v-for="extra of item.deliveryExtra" class="mr-1 badge alert-secondary">
              {{ `${item.delivery_type} ${deliveryExtraMap[extra]}` }}
            </span>
          </template>
          <template v-if="item.goodsType === 'used'">
            <span class="badge alert-warning">빈티지</span><span class="badge badge-light">{{ item.usedGrade }}</span><br/>
          </template>
          <template v-if="!item.imageReady">
            <span class="badge alert-warning">이미지 처리중</span><br/>
          </template>
        </b-col>
        <b-col cols="4">
          <div class="title-sm">매칭정보</div>
          <small>카테고리 : </small>
          <template v-if="item.matched_category">
            <b-badge variant="light">{{item.matched_category}}</b-badge>
            <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.matched_category)"><i class="fa fa-copy"></i></b-badge>
          </template>
          <br/>
          <small>브랜드 : </small>
          <template v-if="item.matched_brandno">
            <b-badge variant="light">{{item.matched_brandno}}</b-badge>
            <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.matched_brandno)"><i class="fa fa-copy"></i></b-badge>
          </template>
          <br/>
          <small>컬러 : </small>
          <template v-if="item.matched_color">
            <b-badge variant="light">{{item.matched_color}}</b-badge>
            <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.matched_color)"><i class="fa fa-copy"></i></b-badge>
          </template>
          <br/>
        </b-col>
      </b-row>

      <b-row class="mb-3" v-if="item.display_status === 'notview' && item.display_status_reason">
        <b-col cols="12">
          <span class="badge badge-warning">상품 미노출 사유</span><br/>
          <span :title="item._notview_dt">[{{reduceDT(item._notview_dt)}}]&nbsp;</span>
          <span v-html="item.display_status_reason"></span>
          <b-badge class="ml-1" variant="info">by {{item._notview_by}}</b-badge>
        </b-col>
      </b-row>

      <div class="title-sm">상품정보</div>
      <b-row class="mb-2">
        <b-col cols="6" sm="3">
          <div class="label-sm">컬러</div>
          {{ item.color || '-' }}
        </b-col>
        <b-col cols="6" sm="3">
          <div class="label-sm">컬러 자동매칭(matched_color)</div>
          {{ item.matched_color || '-' }}
        </b-col>
        <b-col cols="6" sm="3">
          <div class="label-sm">시즌</div>
          {{ item.launch_date || '-' }}
        </b-col>
        <b-col cols="6" sm="3">
          <div class="label-sm">원산지</div>
          {{ item.origin || '-' }}
        </b-col>
        <b-col cols="6" sm="3">
          <div class="label-sm">최초생성위치</div>
          <span v-html="badgeCat(item._cat)"></span>
        </b-col>
        <b-col cols="6" sm="3">
          <div class="label-sm">최초생성시각</div>
          {{ item._cdt || '-' }}
        </b-col>
        <b-col cols="6" sm="3">
          <div class="label-sm">최초송출시각(registered)</div>
          {{ item.registered_dt || '-' }}
        </b-col>
        <b-col cols="6" sm="3">
          <div class="label-sm">최근수정시각</div>
          {{ item._mdt || '-' }}
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="label-sm">소재</div>
          <span v-html="item.composition || '-'"></span>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="label-sm">measurements</div>
          <span v-html="item.measurements || '-'"></span>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="label-sm">modelsize</div>
          <span v-html="item.modelsize || '-'"></span>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col class="mp_desc" cols="12">
          <div class="label-sm">설명 (본문의 이미지는 편의상 400px 로 제한됨) <div v-if="item.longImages">(상품이 등록될 때 긴 이미지가 포함되게 됨)</div></div>
          <div ref="desc" class="mh-600" style="background-color: #f9f9f9; border: 1px solid #eee" v-html="item.description || '-'"></div>
          <b-button block variant="outline-secondary" v-if="$refs.desc && $refs.desc.offsetHeight === 600 || !$refs.desc"
                    @click="e => { e.target.previousSibling.classList.remove('mh-600'); e.target.classList.add('d-none')}">Show More...
          </b-button>
        </b-col>
      </b-row>
      <template v-if="item.consignObj && PRIVATE">
        <div class="title-sm">위탁 정보</div>
        <template v-for="(obj, idx) in [item.consignObj]">
          <b-row class="mb-3" :key="idx">
            <b-col cols="6" lg="3">
              <small>위탁 지점명</small><br/>
              {{obj.branch_name || '-'}}
            </b-col>
            <b-col cols="6" lg="3">
              <small>위탁 판매자 아이디</small><br/>
              {{obj.seller_id || '-'}}
            </b-col>
            <b-col cols="6" lg="3">
              <small>위탁 확인서</small><br/>
              <a class="d-block ellipsis c-black" :href="`/goods/consign/download/${obj.confirmation_url}`" target="_blank" :title="obj.confirmation_url">
                <i class="fa fa-file-o"></i> {{obj.confirmation_url && obj.confirmation_url.split('/').slice(-1)[0] || '-'}}
              </a>
            </b-col>
            <!--
            <b-col cols="6" lg="3">
              <small>위탁 판매자 신분증 사본</small><br/>
              <a class="d-block ellipsis c-black" :href="`/goods/consign/download/${obj.id_card_url}`" target="_blank" :title="obj.id_card_url">
                <i class="fa fa-file-o"></i> {{obj.id_card_url && obj.id_card_url.split('/').slice(-1)[0] || '-'}}
              </a>
            </b-col>
            -->
            <b-col cols="6" lg="3">
              <small>은행명</small><br/>
              {{obj.bank_name || '-'}}
            </b-col>
            <b-col cols="6" lg="3">
              <small>계좌번호</small><br/>
              {{obj.account_number || '-'}}
            </b-col>
            <b-col cols="6" lg="3">
              <small>예금주</small><br/>
              {{obj.owner_name || '-'}}
            </b-col>
          </b-row>
        </template>
      </template>
      <b-button v-if="item.src" variant="light" @click="jsonModal('Src 보기', item.src)">SRC 확인</b-button>
      <template v-slot:modal-footer="{ ok }">
        <b-button v-if="$R('GOODS_R')" class="ml-2" variant="outline-success" @click="getMpES()">
          검색엔진 데이터
        </b-button>
        <b-button class="ml-2"  variant="outline-info"  @click="modal.imageTrimTypeChg = true; imageTrimTypeItem = item;">
          이미지 여백 제거 강도 변경
        </b-button>
        <b-button v-if="$R('DEV')" class="ml-2" variant="light" @click="requestImageWebStore">
          WebStore
        </b-button>
        <b-button v-if="item.price_table && item.price_table.length" class="ml-2" variant="light" @click="jsonModal('원가테이블 확인', item.price_table)">
          원가정보 확인<span v-if="item.price_table.length > 1">({{ item.price_table.length }} 종류)</span>
        </b-button>
        <b-button variant="outline-light" @click="jsonModal('JSON 보기', item)">
          JSON
        </b-button>
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="showDiffModal(item)">
          수정이력
        </b-button>
        <b-button variant="primary" @click="ok()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <b-modal title="ES Query 확인" v-model="modal.esQuery">
      <b-textarea v-model="esQuery" rows="50"></b-textarea>
      <template v-slot:modal-footer="{ ok }">
        <b-button v-if="tabIndex === 1" variant="outline-success" @click="setSampleCustomQuery">
          Sample
        </b-button>
        <b-button v-if="tabIndex === 1" variant="success" @click="runCustomQuery">
          이 쿼리로 실행
        </b-button>
        <b-button variant="primary" @click="ok">
          확인
        </b-button>
      </template>
    </b-modal>

    <b-modal title="전체 검색결과 ID 다운로드" size="lg" v-model="modal.downIds" :no-close-on-backdrop="busy.downIds" :no-close-on-esc="busy.downIds" hide-header-close>
      <b-alert show variant="info">ID 필드를 선택하여 전체 검색결과에 대해 다운로드 합니다.</b-alert>
      <b-alert show variant="warning">다운로드는 200 만개 까지 가능하며, 그 이상은 조건을 조절하여 순차 다운르드 해 주세요. 다운로드에는 10 만개 기준 20 여초 소요됩니다.</b-alert>
      <b-alert show variant="warning">다운로드 중에는 모달을 닫을 수 없습니다. 먼저 검색을 통해 전체 수를 확인 후 다운을 진행해주세요.</b-alert>
      <span>ID Field</span>
      <b-form-radio-group class="col-form-label" v-model="returnIdField" :disabled="busy.downIds" :options="[
        {text: 'GID', value: 'gid'},
        {text: '파트너상품코드(goods_id)', value: 'goods_id'},
        {text: '발란코드(goods_no)', value: 'goods_no'},
        {text: 'ObjectId', value: '_object_id'}
      ]"></b-form-radio-group>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="downIds()" :disabled="busy.downIds">
          다운로드
          <b-spinner class="ml-1" small v-if="busy.downIds"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()" :disabled="busy.downIds">
          닫기
          <b-spinner class="ml-1" small v-if="busy.downIds"></b-spinner>
        </b-button>
      </template>
    </b-modal>

    <template v-if="tabIndex === 0 || tabIndex === 1">
      <div class="clearfix mb-1">
        <div class="pull-left">
          <b-button class="ml-1" @click="approvePrice" :disabled="busy.approvePrice">mapped->confirmed 가격/재고 반영
            <b-spinner class="ml-1" small v-if="busy.approvePrice"></b-spinner>
          </b-button>
          <b-button class="ml-1" @click="applyStatus" :disabled="busy.approvePrice">mapped->confirmed 상태 반영
            <b-spinner class="ml-1" small v-if="busy.approvePrice"></b-spinner>
          </b-button>
          <b-button class="ml-1" @click="imageProcess" :disabled="busy.imageProcess">이미지 처리
            <b-spinner class="ml-1" small v-if="busy.imageProcess"></b-spinner>
          </b-button>
          <b-button class="ml-1" @click="modal.imageTrimTypeChg = true">이미지 여백 제거 강도 변경
            <b-spinner class="ml-1" small v-if="busy.imageTrimTypeChg"></b-spinner>
          </b-button>
          <b-button class="ml-1" v-if="$R('DEV')" :disabled="busy.removeMapped" @click="modal.removeMapped = true">Mapped 삭제
            <b-spinner class="ml-1" small v-if="busy.removeMapped"></b-spinner>
          </b-button>
        </div>

        <div class="pull-right">
          <b-dropdown right variant="light" class="ml-1">
            <template #button-content>
              <i class="fa fa-copy"></i>
            </template>
            <b-dropdown-item @click="copy('goods_id')">Goods ID</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_id', {withQuotes: true})">Goods ID(따옴표)</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_no')">발란코드</b-dropdown-item>
          </b-dropdown>

          <b-dropdown variant="success" class="ml-1" :disabled="busy.xlsxDown">
            <template v-slot:button-content>
              <b-spinner class="mr-2" small v-if="busy.xlsxDown"></b-spinner>
              Xlsx Down
            </template>
            <b-dropdown-item @click="xlsx('normal')">일반 형식</b-dropdown-item>
            <b-dropdown-item @click="xlsx('optmerge')">옵션통합 형식</b-dropdown-item>
            <!--<b-dropdown-item @click="sampleDown('playauto')">플레이오토 샘플</b-dropdown-item>
            <b-dropdown-item @click="sampleDown('shoplinker')">샵링커 샘플</b-dropdown-item>
            <b-dropdown-item @click="sampleDown('sabangnet')">사방넷 샘플</b-dropdown-item>-->
          </b-dropdown>
        </div>

  <!--      <b-dropdown variant="outline-success" class="m-1" :disabled="busy.xlsxUp">
          <template v-slot:button-content>
            <b-spinner class="mr-2" small v-if="busy.xlsxUp"></b-spinner>
            Xlsx Upload
          </template>
          <b-dropdown-item @click="_=>{$refs.xlsxNormal.value = null;$refs.xlsxNormal.click()}">일반 형식</b-dropdown-item>
          <b-dropdown-item @click="_=>{$refs.xlsxOptmerge.value = null;$refs.xlsxOptmerge.click()}">옵션통합 형식</b-dropdown-item>
        </b-dropdown>
        <input type="file" ref="xlsxNormal" data-type="normal" style="display: none" @change="handleXlsx">
        <input type="file" ref="xlsxOptmerge" data-type="optmerge" style="display: none" @change="handleXlsx">-->

  <!--      <div class="pull-right">
          <b-button class="m-1" variant="success" @click="down('xlsx')">XLSX</b-button>
          <b-button class="m-1" variant="success" @click="down('txt')">Text</b-button>
          <a class="m-1 btn btn-outline-success" href="/#/pages/mapped/image" target="_blank">Mapped Image 체크<i class="fa fa-external-link"></i></a>
        </div>-->
      </div>

      <c-table ref="c-table:list" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
               :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
               :caption="`현재 ${items.list.length}${hasMore.list && !total.list ? '+' : ''} 개` +
                `${total.list ? ` (총 ${total.list.value.comma()}${total.list.relation === 'gte' ? '+' : ''} 개)` : ''}`"
               :emptyHtml="'해당 조건에 맞는 상품이 없습니다. 검색조건을 확인해주세요'"
               @btn-clicked="btnAction" @get-more="list(true)">
      </c-table>
    </template>

    <template v-if="tabIndex === 2">
      <b-card class="my-2">
        <pie-chart chart-id="pie" :chartdata="chart.data" :options="chart.options" style="position: relative; height: 450px"></pie-chart>
      </b-card>

      <b-card class="my-2">
        <div class="clearfix mb-1">
          <div class="pull-left col-form-label" v-if="total.stat">
            Total {{total.stat.value.comma()}} / Aggregations {{items.aggs.length}}
          </div>
          <b-btn class="pull-right" variant="success" @click="statXlsx()" :disabled="busy.statXlsx">
            Xlsx Down
            <b-spinner class="ml-1" small v-if="busy.statXlsx"></b-spinner>
          </b-btn>
        </div>

        <hot-table ref="hotTableStat" :settings="hotSettingsStat"></hot-table>
      </b-card>
    </template>

    <b-modal title="Mapped 삭제" v-model="modal.removeMapped" ok-title="삭제" cancel-title="닫기" @ok="removeMapped">
      confirmed가 있는 상품 삭제 허용<br/>
      <b-form-radio-group class="col-form-label" v-model="removeConfirmed" :options="[
            {text: 'confirmed 삭제 허용', value: true},
            {text: 'confirmed 삭제 허용 하지 않음', value: false},
          ]"></b-form-radio-group>
    </b-modal>
    <b-modal title="이미지 여백 제거 강도 변경" v-model="modal.imageTrimTypeChg" ok-title="저장" cancel-title="닫기" @ok="imageTrimTypeChg">
      <small>이미지 Trim 방식
        <a href="/#/shop/imageTrimSample" target="_blank">예시</a>
      </small>
      <i class="fa fa-question-circle ml-1" v-b-tooltip="'이미지의 여백을 날린 뒤 사이즈를 조정할지의 여부입니다.'"></i>
      <br/>
      <b-alert show variant="info">
        이미지의 여백을 날린 뒤 사이즈를 조정할지의 여부입니다.
        <br>
        숫자가 높을 수록 여백을 더 많이 지우게 됩니다.
       </b-alert>
      <br/>
      <b-form-select v-model.number="imageTrimThreshold" :options="[
                      {text: '없음(0)', value: 0},
                      {text: '약하게(0.1)', value: 0.1},
                      {text: '1', value: 1},
                      {text: '2', value: 2},
                      {text: '3', value: 3},
                      {text: '4', value: 4},
                      {text: '5', value: 5},
                      {text: '6', value: 6},
                      {text: '7', value: 7},
                      {text: '8', value: 8},
                      {text: '9', value: 9},
                      {text: '10', value: 10},
                      {text: '11', value: 11},
                      {text: '12', value: 12},
                      {text: '13', value: 13},
                      {text: '14', value: 14},
                      {text: '15', value: 15},
                      {text: '16', value: 16},
                      {text: '17', value: 17},
                      {text: '18', value: 18},
                      {text: '19', value: 19},
                      {text: '20', value: 20},
                      {text: '21', value: 21},
                      {text: '22', value: 22},
                      {text: '23', value: 23},
                      {text: '24', value: 24},
                      {text: '25', value: 25},
                    ]">
      </b-form-select>
    </b-modal>
  </div>
</template>

<script>
import {down} from '@/shared/impexp'
import * as momentBiz from 'moment-business-days';
import FormFields from "../modules/FormFields";
import FormOptions from "../modules/FormOptions";
import FormIncExc from "../modules/FormIncExc";
import FormPreset from "../FormPreset";
import ColorCheckbox from "../modules/ColorCheckbox";
import {formOptionsPreset} from "@/shared/fields";
import PieChart from '@/views/charts/Pie.vue'
import {Types} from 'mongoose';
import Vue from "vue";
import GoodsMixin from '@/views/goods/GoodsMixin'
import ListDataMixin from '../modules/ListDataMixin'
import {formFieldsGen, formIncExcGen, formOptionsGen, groupFieldsGen} from "./Mapped/formData";

export default {
  name: 'MappedList',
  title: 'Mapped 조회',
  mixins: [
    GoodsMixin,
    ListDataMixin
  ],
  components: {FormFields, FormOptions, FormIncExc, FormPreset, ColorCheckbox, PieChart},
  data() {
    return {
      momentBiz,
      shopMap: {},
      brandMap: {},
      categoryMap: {},

      tabIndex: 0,
      defaultForm: {
        list: {
          // search: '',
          // shop: [],
          // brand: [],
          // category: [],
          // manual: 'ALL',
          goodsType: 'ALL',
          usedGrade: this.$C.USED_GRADE.map(e => e.value),
          imageReady: 'ALL',
          launch_date: '',
          discountFrom: '',
          discountTo: '',
          goods_id: '',
          gid: '',
          tot_stock: '',
          opt_stock: '',
          new_goods_id: false,
          include_not_found: false,
          minimal: false,
          hasGoodsNo: 'ALL',
          // skip: 0,
          // limit: 100,

          // for es
          search: '',

          shop: [],
          brand: [],
          category: [],
          shop_exclude: [],
          brand_exclude: [],
          category_exclude: [],

          goods_status: 'ALL',
          display_status: 'ALL',
          stock_status: 'ALL',
          manual: 'ALL',

          delivery_type: 'ALL',
          oneday_delivery: 'ALL',
          today_pick: 'ALL',
          orderMade: 'ALL',
          deliveryExtra: 'ALL',
          store_pickup: 'ALL',
          gift_packing: 'ALL',
          hide_es: 'ALL',
          matched_color: this.$C.COLORS.map(e => e.name),

          limit: 100,
          skip: 0,

          sortKey: '_object_id',
          sortDir: 'desc',
          fields: {},
          incExc: {},
        },
        aggs: {
          // for stat
          groupFields: [],
          groupFieldSetting: {},
        }
      },
      form: {
        list: {},
        aggs: {},
      },
      lastUri: '',
      esQuery: '',
      returnIdField: 'gid',
      collapse: {detail: false, esDetail: false, statDetail: false},
      lastBody: {list: {}},
      items: {list: [], aggs: [], xlsx: []},
      total: {list: null, stat: null}, // list: {value: 0, relation: 'eq'}
      busy: {list: false, listmore: false, xlsxDown: false, approvePrice: false, stat: false, statXlsx: false, removeMapped: false, downIds: false, imageTrimTypeChg: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController


      lastForm: {},
      imageTrimTypeItem: null,
      item: {},
      fields: {
        list: [
          'selected',
          {key: '_img60', label: '이미지', class: 'text-center'},
          {
            key: 'html1',
            label: `<span class="badge badge-success">SHOP</span><span class="badge badge-warning">브랜드</span>` +
              `<span class="badge badge-light">카테고리</span><span class="badge badge-primary">발란코드</span><br/>` +
              `상품정보`,
            style: {minWidth: '250px'}
          },
          {
            key: 'html2',
            label: '<span class="badge badge-light">goods_id</span><br/><span class="badge badge-light">SKU ID</span><br/>' +
              '<span class="badge badge-light">통합 SKU</span>',
            class: 'text-center'
          },
          {
            key: 'html3',
            label: '<span class="badge badge-light">생성위치</span><br/><span class="badge badge-light">생성시각</span>',
            class: 'text-center',
            style: {minWidth: '105px'}
          },
          {
            key: 'html4',
            label: '<span class="badge alert-success">시즌</span><br/>' +
              '<span class="badge alert-light">컬러</span><br/>' +
              '<span class="badge alert-warning">원산지</span>',
            class: 'text-center'
          },
          {key: 'html5', label: '배송정보', class: 'text-center', style: {minWidth: '105px'}},
          {key: 'html6', label: '가격', class: 'text-right'},
          {key: 'html7', label: '재고', class: 'text-center', style: {width: '50px'}},
          {key: 'html8', label: '상태', class: 'text-center'},
          {key: '_actions', label: '상세', style: {width: '55px', textAlign: 'center'}, buttons: [{label: '상세', event: 'show_modal'}]},
        ]
      },
      perPage: 20,

      modal: {detail: false, esQuery: false, downIds: false, imageTrimTypeChg: false, removeMapped: false},
      diff: null,
      today: momentBiz().format('YYYY-MM-DD'),
      aggs: '',
      imageTrimThreshold: null,
      removeConfirmed: false,

      formOptions: formOptionsGen(),
      formFields: formFieldsGen(),
      defaultFields: 'price:range,sku_id:eq,matched_sku_id:eq,options.Size:like',
      customFormFields: [],
      formIncExc: formIncExcGen(),
      defaultIncExc: 'goods_id,gid,goods_no,matched_sku_id',
      customFormIncExc: [],

      // stat
      groupFields: groupFieldsGen(),
      chart: {
        data: {
          labels: ['Chart'],
          datasets: [{data: [1]}]
        },
        options: {
          // title: {display: true, text: 'Chart Title'},
          legend: {
            position: 'left'
          }
        }
      },
      hotSettingsStat: {
        colHeaders: [],
        columns: [],
        className: "htMiddle",
        autoWrapCol: true,
        autoWrapRow: false,
        columnSorting: true,
        manualColumnResize: true,
        width: '100%',
        height: 450,
        stretchH: "all",
        licenseKey: 'non-commercial-and-evaluation',
      },
      deliveryExtraMap: this.$utils.arr2map(this.$C.DELIVERY_EXTRA, 'value', 'text'),
    }
  },
  sockets: {
    mapped_query(q) {
      console.log(q);
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'collapse,perPage');
    Vue.set(this.form, 'list', this.$utils.clone(this.defaultForm.list));
    Vue.set(this.form, 'aggs', this.$utils.clone(this.defaultForm.aggs));
    for (const f of this.groupFields) {
      f.label = `${f.text}(${f.value})`;
    }

    this.busy.list = true;

    // let meta = await this.$api.getMeta('shop,brand,category,holiday');
    // if (!meta) return;
    //
    // // 공휴일 설정
    // let holidays = meta.holiday.map(e => {
    //   if (e.require) return momentBiz().format('YYYY-') + e.date;
    //   return e.date;
    // });
    // // 작년, 내년도 추가한다
    // holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().add(1, 'year').format('YYYY-') + e.date));
    // holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().subtract(1, 'year').format('YYYY-') + e.date));
    // momentBiz.updateLocale('kr', {
    //   holidays: holidays,
    //   holidayFormat: 'YYYY-MM-DD'
    // });
    // window.moment = momentBiz;
    //
    // meta.shop.forEach(s => {
    //   s.value = s.boutique;
    //   s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
    //   this.shopMap[s.shop_id] = s;
    // });
    //
    // meta.brand.forEach(e => {
    //   this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    // });
    //
    // meta.category.map(e => {
    //   return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    // }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));

    // const meta = await this.$api.getMeta('shop,brand,category,holiday');
    // if (!meta) return; // 미로그인 등으로 값이 없을 때
    await this.$api.getAllMeta();
    this.shop = this.$S.m.shop.list.slice();
    this.shopMap = this.$S.m.shop.map;
    this.brand = this.$S.m.brand.list.slice();
    this.brandMap = this.$S.m.brand.map;
    this.category = this.$S.m.category.list.slice();
    this.categoryMap = this.$S.m.category.map;

    // query string 으로 받은 내용을 폼에 적용한다.
    if (Object.keys(this.$route.query).length) {
      Object.keys(this.$route.query).forEach(k => {
        if (this.$route.query[k] != null) {
          const v = this.$route.query[k];
          if ('discountFrom,discountTo,tot_stock,opt_stock,limit,skip'.split(',').includes(k)) {
            this.form.list[k] = +v;
          } else if ('new_goods_id,include_not_found,minimal'.split(',').includes(k)) {
            this.form.list[k] = !(!v || v === 'false');
          } else if (k === '$shop') {
            this.form.list.shop = v.split(',').map(e => this.shopMap[e]);
          } else if (k === '$shop_exclude') {
            this.form.list.shop_exclude = v.split(',').map(e => this.shopMap[e]);
          } else if (k === '$category') {
            this.form.list.category = v.split(',').map(e => this.categoryMap[e]);
          } else if (k === '$category_exclude') {
            this.form.list.category_exclude = v.split(',').map(e => this.categoryMap[e]);
          } else if (k === '$brand') {
            this.form.list.brand = v.split(',').map(e => this.brandMap[e]);
          } else if (k === '$brand_exclude') {
            this.form.list.brand_exclude = v.split(',').map(e => this.brandMap[e]);
          } else if (k.startsWith('collapse.')) {
            const key = k.replace('collapse.', '');
            this.collapse[key] = !(!v || v === 'false');
          } else if (k === 'usedGrade' || k === 'matched_color') {
            this.form.list[k] = v.split(',');
          } else if (k === 'tabIndex') {
            this.tabIndex = +v;
          } else if (Object.keys(this.form.list).includes(k)) {
            this.form.list[k] = v;
          }
        }
      });
    }

    const id = this.$route.params.id;
    if (id) {
      this.form.list.search = id;
      await this.list();
      let item = this.items.list.filter(e => e.goods_id === id || e.src_id === id || e.sku_id === id)[0];
      if (item) this.showModal({item});
      return;
    }

    this.list();
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e => e).forEach(e => e.abort());
    this.$utils.setStatus(this.$options.name, this, 'collapse,perPage');
  },
  watch: {
    perPage: function () {
      this.$utils.setStatus(this.$options.name, this, 'perPage');
    }
  },
  methods: {
    toggleUsedGrade(grade) {
      if (!grade) {
        this.form.list.usedGrade = this.form.list.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.form.list.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },
    makeListFormBody() {
      const form = this.form.list;

      const shop = form.shop.map(e => e.shop_id);
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const shop_exclude = form.shop_exclude.map(e => e.shop_id);
      const brand_exclude = form.brand_exclude.map(e => e.value);
      const category_exclude = form.category_exclude.map(e => e.value);
      const matched_color = form.matched_color.length === this.$C.COLORS.length ? [] : form.matched_color; // 전체 선택일 경우 비우기

      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];
      const incExc = this.$refs.incExc && this.$refs.incExc.makeIncExcQuery() || [];

      const body = {
        ...form,
        fields,
        incExc,
        shop,
        brand,
        category,
        shop_exclude,
        brand_exclude,
        category_exclude,
        matched_color
      };
      return body;
    },
    async list(more) {
      const form = this.form.list;
      if (form.limit > 10000 && !form.minimal) return alert('10000 개를 초과하여 검색할때는 필수정보만 가져오기를 선택해주세요');
      const body = this.makeListFormBody();
      this.lastUri = more === true ? this.lastUri : (this.tabIndex === 0 ? '/goods/mapped' : '/goods/mapped/es');
      const j = await this.$api.postTable(this, this.lastUri, body, {more, fnAssign: this.assignTableData});
    },
    async getEsQuery() {
      const body = this.makeListFormBody();
      body.returnQuery = true;
      const j = await this.$api.postJson('/goods/mapped/es', body);
      if (j) {
        this.esQuery = JSON.stringify(j.query, null, 2);
        this.modal.esQuery = true;
      }
    },
    async runCustomQuery() {
      try {
        const customQuery = JSON.parse(this.esQuery);
        const form = this.form.list;
        if ((form.limit > 10000 || customQuery.size > 10000) && !form.minimal) return alert('10000 개를 초과하여 검색할때는 필수정보만 가져오기를 선택해주세요');
        const body = this.makeListFormBody();
        this.lastUri = '/goods/mapped/es';
        this.modal.esQuery = false;
        await this.$api.postTable(this, this.lastUri, {...body, customQuery}, {more: false, fnAssign: this.assignTableData});
      } catch (e) {
        alert('JSON 형식에 맞게 입력해주세요');
      }
    },
    async setSampleCustomQuery() {
      const q = {
        "query": {
          "bool": {
            "must_not": [
              {
                "regexp": {
                  "name": {
                    "value": "(OutStock|Disconnected).*",
                    "case_insensitive": true
                  }
                }
              }
            ],
            "filter": [
              {
                "script": {
                  "script": {
                    "source": "doc['price'].size() != 0 && doc['price'].value % 10 != 0;"
                  }
                }
              }
            ]
          }
        },
        "_source": false,
        "from": 0,
        "size": 100,
        "sort": [
          {
            "_object_id": "desc"
          }
        ]
      };
      this.esQuery = JSON.stringify(q, null, 2);
    },
    async downIds() {
      const body = this.makeListFormBody();
      body.returnIdField = this.returnIdField;
      this.busy.downIds = true;
      const j = await this.$api.postJson('/goods/mapped/es', body);
      if (j) {
        down(j.data.map(e => ({id: e})), [body.returnIdField], ['id'], `MappedIds_${this.$utils.dt()}`, 'txt');
      }
      this.busy.downIds = false;
    },

    assignTableData(e) {
      if (this.lastBody.list.minimal) {
        e.html2 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. </a> <span class="badge badge-light">${e.goods_id}</span>`;
        return;
      }

      let deliveryDay;
      const shop = this.shopMap[e.shop_id];
      if (shop) { // shop 이 use_yn = N 이 아니라 아예 삭제된 경우에 대비
        e.shop = shop.boutique;
        deliveryDay = shop.delivery_day;
        if (shop.delivery_type === 'both' && e.delivery_type === '해외') {
          deliveryDay = shop.delivery_day_abroad;
        }
        e.delivery_day = deliveryDay;
        e.delivery_str = e.delivery_type + (shop.logistics === 'direct' ? (e.delivery_type === '해외' && shop.pccc_feed === 'y' ? '구매대행' : '직배송') : '');
      } else {
        e.delivery_str = 'SHOP배송정보없음';
      }
      if (!e.consumer) e.consumer = e.price;
      if (!e.goods_status) e.goods_status = 'unregistered';
      e.stock_status = e.tot_stock > 0 ? 'normal' : 'runout';
      e._cdt = e._cdt || this.$utils.kstDT(Types.ObjectId(e._id).getTimestamp());
      e.img = e.images && e.images[0] ? `https://i.balaan.io/${e.image_path}${e.images[0].thumb_200}` : e.img_urls[0];
      e._capp = this.cat2capp(e._cat);

      const goodsIdLink = `<a href="${`/r?u=${encodeURIComponent(e.crawl_url)}`}" target="_blank" class="badge badge-info">` +
        `${e.goods_id} <i class="fa fa-external-link"></i></a>`;
      const skuLinkLambda = e => `<a href="https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${encodeURIComponent(e)}"` +
        ` target="_blank" class="badge badge-success">` +
        `<span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block; max-width: 80px;">${e}</span>` +
        ` <i class="fa fa-external-link"></i></a>`;
      const styleEllipsis = 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block;';

      e.html1 = this.makeShopBadge(shop) + ' ' + this.makeBrandBadge(e.matched_brandno, e.brand_name, {type: (this.brandMap[e.matched_brandno] || '').brand_type}) + ' ' +
        this.makeCategoryBadge(e.origin_category) + ' ' + (e.goods_no ? this.makeGoodsNoLinkBadge(e.goods_no) : '') + `<br/>` +
        `${e.name}<br/>` + this.makeOptionsBadge(e.options, {lines: 2});
      e.html2 = (e.crawl_url && e.crawl_url.startsWith('http') ? goodsIdLink : `<span class="badge badge-light">${e.goods_id}</span>`) + `<br/>` +
        (e.sku_id ? skuLinkLambda(e.sku_id) : '') + `<br/>` +
        (e.matched_sku_id ? skuLinkLambda(e.matched_sku_id) : '') + `<br/>`;
      e.html3 = this.badgeCat(e._cat) + `<br/><span class="badge alert-light" title="${e._cdt}">${this.reduceDT(e._cdt)}</span>`;
      e.html4 = (e.launch_date ? `<span class="badge alert-success" title="${(e.launch_date || '').escapeHtml()}" style="${styleEllipsis} max-width: 80px;">` +
        `${e.launch_date}</span>` : '') + '<br/>' +
        (e.color ? `<span class="badge alert-light" title="${(e.color || '').escapeHtml()}" style="${styleEllipsis} max-width: 80px;">` +
          `${e.color}</span>` : '') + '<br/>' +
        (e.origin ? `<span class="badge alert-warning" title="${(e.origin || '').escapeHtml()}" style="${styleEllipsis} max-width: 80px;">${e.origin}</span>` : '');
      e.html5 = `<span class="badge alert-${e.delivery_type === '해외' ? 'success' : 'info'}">${e.delivery_str}</span>` +
        (!e.orderMade && (!e.deliveryExtra || !e.deliveryExtra.includes('long')) ? `<span class="badge badge-light">${e.delivery_day}일</span><br/>` : '' )+
        (e.oneday_delivery === 'Y' ? `<span class="badge alert-danger ml-1">오늘도착 가능</span>` : '');
      e.html6 = (e.consumer === e.price ? '' :
          `<del>${e.consumer ? this.$utils.rnc(e.consumer, 2) : ''} ${e.price_unit && e.price_unit !== 'KRW' ? e.price_unit : ''}</del><br/>`) +
        `${e.price ? this.$utils.rnc(e.price, 2) : ''} ${e.price_unit && e.price_unit !== 'KRW' ? e.price_unit : ''}<br/>` +
        (e.consumer === e.price ? '' : `(${100 - Math.round(e.price / e.consumer * 100)}%)`);
      e.html7 = e.tot_stock;
      e.html8 = this.makeGoodsStatusBadges(e);
    },
    async stat() {
      const form = this.form.list;
      const aggs = this.form.aggs;
      if (aggs.groupFields.length === 0) return alert('1 개 이상의 Group By 필드를 선택해 주세요');
      if (aggs.groupFields.find(e => e.includes('.'))) {
        const firstNestedIndex = aggs.groupFields.indexOf(aggs.groupFields.find(e => e.includes('.')));
        if (aggs.groupFields.slice(firstNestedIndex + 1).some(e => !e.includes('.'))) {
          return alert('nested field(option.Size 등 depth 가 있는 내부 필드) 는 일반 field 뒤에 존재해야 합니다');
        }
      }

      const shop = form.shop.map(e => e.shop_id);
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const shop_exclude = form.shop_exclude.map(e => e.shop_id);
      const brand_exclude = form.brand_exclude.map(e => e.value);
      const category_exclude = form.category_exclude.map(e => e.value);
      const matched_color = form.matched_color.length === this.$C.COLORS.length ? [] : form.matched_color; // 전체 선택일 경우 비우기

      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];
      const incExc = this.$refs.incExc && this.$refs.incExc.makeIncExcQuery() || [];

      const body = {
        form: {
          ...form,
          fields,
          incExc,
          shop,
          brand,
          category,
          shop_exclude,
          brand_exclude,
          category_exclude,
          matched_color,
        },
        aggs
      };
      this.busy.stat = true;
      const j = await this.$api.postJson('/goods/mapped/aggs', body);
      this.busy.stat = false;
      if (j) {
        this.items.aggs = j.rows;
        const top19 = j.rows.slice(0, 19);
        this.chart.data = {
          labels: top19.map(e => aggs.groupFields.map(f => e[f] == null ? `(null)` : `${e[f]}`).join(' / ')),
          datasets: [{data: top19.map(e => e.count)}]
        };
        if (j.rows.length > 19) {
          const etcCount = j.rows.slice(19).sum('count');
          this.chart.data.labels.push('(기타)');
          this.chart.data.datasets[0].data.push(etcCount);
        }
        this.total.stat = j.total;

        const hs = this.$utils.clone(this.hotSettingsStat);
        hs.colHeaders = aggs.groupFields.map(f => [f, f + ' count']).flat().slice(0, -1).concat(['count']);
        hs.columns = hs.colHeaders.map(e => ({data: e, readOnly: true, renderer: 'nullish'}));
        // hs.columns = hs.colHeaders.map(e => ({data: e, readOnly: true}));
        this.$refs.hotTableStat.hotInstance.updateSettings(hs);
        this.$refs.hotTableStat.hotInstance.loadData(j.rows);

        this.$forceUpdate();
      }
    },
    groupFieldsChange() {
      const settingKeyMap = this.$utils.arr2map(Object.keys(this.form.aggs.groupFieldSetting));
      this.form.aggs.groupFields.forEach(f => {
        if (!settingKeyMap[f]) {
          const orgField = this.groupFields.find(e => e.value === f);
          this.form.aggs.groupFieldSetting[f] = {size: 100, type: orgField.type, nested: orgField.nested};
        }
        delete settingKeyMap[f];
      });
      Object.keys(settingKeyMap).forEach(k => {
        delete this.form.aggs.groupFieldSetting[k];
      });
    },
    statXlsx() {
      const keys = this.form.aggs.groupFields.map(f => [f, f + ' count']).flat().slice(0, -1).concat(['count']);
      down(this.items.aggs, keys, keys, `MappedStat_${this.$utils.dt()}`, 'xlsx');
    },
    resetForm() {
      const fields = this.form.list.fields;
      const incExc = this.form.list.incExc;
      this.form.list = this.$utils.clone(this.defaultForm.list);
      Vue.set(this.form.list, 'fields', fields);
      Vue.set(this.form.list, 'incExc', incExc);

      this.$refs.fields.resetFieldValues();
      this.$refs.incExc.resetValues();
    },
    resetStatForm() {
      this.resetForm();
      this.form.aggs = this.$utils.clone(this.defaultForm.aggs);
    },

    showModal(row) {
      const item = this.item = row.item;

      this.$api.postJson('/goods/mapped/detail', {_id: item._id}).then(j => {
        Object.assign(item, j.detail);
        // _diff 가 있다면 최근 가격변경, 재고변경일, src_not_found 를 가져온다
        if (item._diff && item._diff.length) {
          item.stock_diff = item._diff.filter(e => e.tot_stock).reverse()[0];
          item.price_diff = item._diff.filter(e => e.price).reverse()[0];
          item.not_found = item._diff.filter(e => e.src_not_found).reverse()[0];
        }
        if (item.consignUsed && j.consignObj) {
          item.consignObj = j.consignObj;
        }
        this.modal.detail = true;
      });

      this.$api.postJson('/goods/src', {form: {shop_id: item.shop_id, src_id: item.src_id}}).then(j => {
        if (!j || j.list.length === 0) return;
        Vue.set(this.item, 'src', j.list);
      });

    },
    showDiffModal(item) {
      this.$modal.show({title: '수정이력 확인', type: 'diff', item: item, db: 'secondDB', collection: 'mapped'});
    },
    btnAction(row, event) {
      if (event === 'show_modal') {
        this.showModal(row);
      }
    },
    priceTable() {
      this.$modal.show({title: '원가테이블 확인', html: '<pre>' + JSON.stringify(this.item.price_table, null, 2) + '</pre>'});
    },
    jsonModal(title, json) {
      this.$modal.show({title, type: 'json', item: json});
    },
    copyText(e) {
      let res = this.$utils.copyToClipboard(e);
      if (res) this.$alertTop(`"${e}" 로 복사되었습니다`);
    },
    async approvePrice() {
      const selected = this.items.list.filter(e => e.selected).map(e => e.goods_no);
      if (!selected.length) return alert('가격을 반영할 상품을 선택해주세요');
      this.busy.approvePrice = true;
      const res = await this.$api.postJson('/price/renewPriceAll', {goodsNos: selected, direct: true});
      this.busy.approvePrice = false;
      if (res) {
        alert(`${selected.length} 개 상품의 가격 및 재고가 반영되었습니다. 외부몰은 백그라운드에서 순차적으로 진행됩니다.${res.errCnt ? `\n\n일부 상품에서 다음 에러가 발생했습니다.\n${JSON.stringify(res.err, null, 2)}` : ''}`);
      }
    },
    async imageProcess() {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('이미지를 처리할 반영할 상품을 선택해주세요');
      this.busy.imageProcess = true;
      let res = await this.$api.postJson('/goods/requestMappedImageProcessing', {mids: selected.map(e => `${e.shop_id}|${e.goods_id}`)});
      this.busy.imageProcess = false;
      if (res) {
        alert(`${selected.length} 개 상품의 이미지 처리가 요청되었습니다. 백그라운드에서 ${res.cnt} 개 상품의 이미지 처리가 순차적으로 진행됩니다.`);
      }
    },
    async requestImageWebStore() {
      const item = this.item;
      const j = await this.$api.postJson('/goods/requestImageWebStore', {shop_id: item.shop_id, goods_id: item.goods_id});
      if (j) {
        this.$alertTop(`요청되었습니다`);
      }
    },
    async getMpES() {
      this.busy.getMpES = true;
      let j = await this.$api.getJson(`/goods/mapped/getES?_id=${this.item._id}`);
      this.busy.getMpES = false;
      if (j) {
        this.$modal.show({title: 'ES Solution 의 second.mapped 데이터입니다', type: 'json', item: j.data});
      }
    },
    xlsx(type) {
      const selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('다운받을 상품을 선택해주세요');

      const entries = Object.entries(this.$C.MAPPED_COLUMNS).filter(([, v]) => !v.group || v.group === type);
      const keys = entries.map(([k]) => k);
      const values = entries.map(([, v]) => v);
      const name = values.map(e => e.name);

      // _capp 을 끼워넣는다.
      const img_urls_idx = keys.indexOf('img_urls'); // 이미지 경로
      keys.splice(img_urls_idx, 0, '_capp');
      name.splice(img_urls_idx, 0, '최초생성위치');
      keys.splice(img_urls_idx + 1, 0, '_cdt');
      name.splice(img_urls_idx + 1, 0, '최초생성시각');

      const header = {};
      keys.forEach(k => header[k] = k);
      const rows = [header];

      if (type === 'optmerge') {
        selected.forEach(e => {
          let optnm = e.optnm || ((e.options || [])[0] || {})['optnm'] || '사이즈';
          rows.push({
            ...e,
            img_urls: e.img_urls.join('|'),
            optnm,
            options: e.options.map(o => `${o.Size}||${o.goods_consumer}||${o.goods_price}||${o.goods_supply || ''}||${o.stock}`).join('^^')
          });
        });
        down(rows, name, keys, `${'MappedOptMerge'}_${this.$utils.dt()}`, 'xlsx');
      } else {
        selected.forEach(e => {
          e.options.forEach(opt => {
            rows.push({
              ...e,
              img_urls: e.img_urls.join('|'),
              optName: opt.optnm,
              optSize: opt.Size,
              optConsumer: opt.goods_consumer,
              optPrice: opt.goods_price,
              optSupply: opt.goods_supply,
              optStock: opt.stock
            });
          });
        });
        down(rows, name, keys, `${'Mapped'}_${this.$utils.dt()}`, 'xlsx');
      }
    },
    async sampleDown(type) {
      let j = await this.$api.getJson('/goods/srcSample?type=' + type);
      if (!j) return;

      let COLS = {
        sabangnet: this.$C.SABANGNET_COLS,
        playauto: this.$C.PLAYAUTO_COLS,
        shoplinker: this.$C.SHOPLINKER_COLS,
      }[type];
      let name = COLS.map(e => e.name);
      down(j.list, name, name, `${type}_sample_${this.$utils.dt()}`, 'xlsx');
    },
    copy(col, {withQuotes = false} = {}) {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('복사할 상품을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.map(e => withQuotes ? `'${e[col].toString().replace(/'/g, "\\'")}'` : e[col]).join(withQuotes ? ',\n' : '\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },
    async imageTrimTypeChg() {
      let selected = this.items.list.filter(e => e.selected);
      if (this.imageTrimTypeItem) {
        selected = [this.imageTrimTypeItem]
      }
      if (!selected.length) {
        return alert('이미지 trim size를 변경할 상품을 선택해주세요.');
      }
      this.busy.imageTrimTypeChg = true;
      let res = await this.$api.postJson('/goods/imageTrimTypeChg', {
        gids: selected.map(item => `${item.shop_id}|${item.goods_id}`),
        imageTrimThreshold: this.imageTrimThreshold
      });
      if (res) {
        alert(`${res.cnt} 개 상품의 이미지 여백 강도가 변경되었습니다. 이미지 처리 버튼을 통해 이미지 재처리를 진행해주세요.`);
      }
      this.busy.imageTrimTypeChg = false;
      this.imageTrimTypeItem = null
      this.imageTrimThreshold = null
    },
    async removeMapped() {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) {
        return alert('삭제할 상품을 선택해주세요.');
      }
      if (!this.removeConfirmed && selected.filter(e => e.goods_no).length) {
        return alert('이미 등록되어있는 상품이 있습니다. confirmed까지 삭제하려면 confirmed 삭제 허용을 체크해주세요.');
      }

      this.busy.removeMapped = true;
      let res = await this.$api.postJson('/goods/removeMapped',
          {gids: selected.map(e => `${e.shop_id}|${e.goods_id}`), removeConfirmed: this.removeConfirmed});
      this.busy.removeMapped = false;
      if (res) {
        alert(`${res.cnt} 개 상품의 삭제가 요청되었습니다.`);
      }
    },
    async applyStatus() {
      const selected = this.items.list.filter(e => e.selected).map(e => e.goods_no);
      if (!selected.length) return alert('상태를 반영할 상품을 선택해주세요');
      this.busy.applyStatus = true;
      const res = await this.$api.postJson('/goods/mapped/applyStatus', {goodsNos: selected});
      this.busy.applyStatus = false;
      if (res) {
        alert(`${selected.length} 개 상품의 상태가 반영되었습니다.`);
      }
    }
    /*async handleXlsx(event) {
      let file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');
      let {headers, rows} = await readXlsx(file);
      let type = event.target.dataset.type;
      if (type === 'normal' || type === 'optmerge') {
        this.uploadXlsx(event.target, headers, rows);
      }
    },
    async uploadXlsx(target, headers, rows) {
      /!**
       * 발란 형식 업로드
       *!/

      let type = target.dataset.type;
      let entries = Object.entries(this.$C.MAPPED_COLUMNS).filter(([k, v]) => !v.group || v.group === type)
        , keys = entries.map(([k, v]) => k)
        , values = entries.map(([k, v]) => v);
      let colNameMap = {};
      entries.forEach(([k, v]) => {
        v.key = k;
        colNameMap[v.name] = v;
      });
      let unknown = headers.filter(e => !colNameMap[e]);
      if (unknown.length) return alert('알 수 없는 컬럼들이 있습니다:\n' + unknown.join('\n'));
      // let notAllow = headers.filter(e=>!colNameMap[e].upload);
      // if (notAllow.length) return this.$utils.alert('업로드 할 수 없는 컬럼들이 있습니다:\n'+notAllow.join('\n'));
      let required = values.filter(e => e.required).map(e => e.name).filter(e => !~headers.indexOf(e));
      if (required.length) return alert('필수 컬럼이 빠져있습니다:\n' + required.join('\n'));

      let wrongRows = [];
      rows.slice(1).forEach((e, i) => {
        let wrongCols = [];
        headers.forEach(h => {
          let tester = colNameMap[h].test;
          if (e[h] != null && e[h] !== '' && tester && !tester.test(e[h])) {
            wrongCols.push(`${h}: ${e[h]}`);
          }
          if ((e[h] == null || e[h] === '') && colNameMap[h].required) {
            wrongCols.push(`${h}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx: i, cols: wrongCols});
      });
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n' + wrongRows.map(e => `${e.idx + 3} 번째줄 ${e.cols.map(e => e).join(', ')}`).join('\n'));

      // 첫 행은 key 행이어서 별도로 사용하지 않는다.
      // 두 번째 행부터의 데이터를 키 값으로 재매핑 한다.
      rows = rows.slice(1).map(e => {
        let obj = {};
        headers.forEach(name => {
          let col = colNameMap[name];
          if (e[name] === undefined) {
            obj[col.key] = "";
          } else {
            obj[col.key] = e[name];
          }
        });
        return obj;
      });

      // 컬럼 정의에 type 이 있는 경우 해당 타입으로 casting 한다.
      let typeHeaders = headers.filter(h => colNameMap[h].type);
      rows.forEach(row => {
        typeHeaders.forEach(h => {
          let {key, type} = colNameMap[h];
          if (type === 'number') {
            row[key] = +row[key];
          } else if (type === 'string') {
            row[key] = '' + row[key];
          }
        });
      });
      let j = await this.$api.postJson('/goods/uploadSrc', {type: target.dataset.type, rows});
      if (j.ok === 1) {
        this.$utils.alert(`${j.cnt}건 정상적으로 업로드 되었습니다`);
        this.list();
      } else if (j.ok === -1) {
        let body = `<h4>${j.msg}</h4>` + j.errors.map(e => `<span class="badge badge-light">${e.goods_id}</span> - ${e.error || ''}`).join('<br/>');
        this.$modal.show({title: '업로드 에러 확인', html: '<pre>' + body + '</pre>'});
      }
      target.value = "";
    }*/
  }
}
</script>

<style>
.mp_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.mp_desc img {
  width: 100%;
  max-width: 400px !important;
  margin: auto;
}

.mp_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}
</style>
